import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "jadwalList";
const modelPath = "/stase/jadwal-departemen/";

class JadwalDepartemenList extends ListModelBase {
  constructor() {
    let filterMap = { cari: "" }
    super(modelName, modelPath, filterMap);
  }

  delete(id, onDeleted) {
    let url = `${this.apiURL}${id}/`;
    this.observables.loading = true;
    axios.delete(url)
      .then(() => {
        Toast.open("Data berhasil di hapus.");
        if (onDeleted) {
          onDeleted();
        }
      })
      .catch(() => {
        Toast.open("Gagal menghapus.");
      })
      .finally(() => {
        this.observables.loading = false;
      });
  }
}

export default JadwalDepartemenList;